import React from 'react'
import { useEffect, useState } from 'react'
import {Auth} from 'aws-amplify'
import { Route, NavLink, Switch} from 'react-router-dom'

const SubMenu = ({componentOptions: ComponentOptions}) => {

    const [cognitoGroups, setCognitoGroups] = useState()

    const getCognitoGroups = async () => {
        const {accessToken} = await Auth.currentSession()
        setCognitoGroups(accessToken.payload['cognito:groups'])
    }

    useEffect(() => {
        getCognitoGroups()
    }, [])
    
    return (
    <div className="menu">
        <div className="flex__container">
            <div className="menu__content">
                <ul>
                    {ComponentOptions.map(({path, title, visibility, authorized}, index) => {
                        if (visibility !== 'hidden') {
                            if (!authorized || (cognitoGroups && cognitoGroups.includes(authorized)) ) {
                                return (
                                    <li key={index} className="menu__title">
                                        <NavLink to={path} className="menu__title" activeClassName="menu__title__active">{title}</NavLink>
                                    </li>
                                )
                            }
                        }
                    })}
                </ul>
            </div>
            <div className="flex__report">
                <Switch>
                    {ComponentOptions.map(({path, component, authorized}, index) => {
                        if (!authorized || (cognitoGroups && cognitoGroups.includes(authorized)) ) {
                            return (
                                <Route key={index} path={path} component={component} />
                            )
                        }
                    })}
                </Switch>
            </div>
        </div>
    </div>
    )
}

export default SubMenu