import React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

export default class DateRange extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            focusedInput: false
        }
    }

    onDatesChange = ({ startDate, endDate }) => {
        if (startDate) {
            this.props.updateStartDate(startDate)
        }
        if (endDate) {
            this.props.updateEndDate(endDate)
        }        
    }

    render() {
        return (
            <div className="reportdate">
                <div className="reportdate__title">
                    <h4>Date Range:</h4>
                </div>
                    <DateRangePicker
                                startDate={this.props.startDate}
                                startDateId={this.props.startDateId}
                                endDate={this.props.endDate}
                                endDateId={this.props.endDateId}
                                onDatesChange={this.onDatesChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                isOutsideRange={() => false}
                                small
                    />
            </div>
        )
    }
}