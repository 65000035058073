import logo from '../assets/images/pointe-sapin-seafoods-logo.png'

const WelcomePage = () => (
    <div className="welcome">
        <div>
            <h1 className="welcome__title">Welcome</h1>
            <img className="welcome__photo" src={logo} alt="Pointe-Sapin Seafoods logo" />
        </div>
    </div>
)

export default WelcomePage