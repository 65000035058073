import { useState, useMemo } from 'react'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import XLSX from 'xlsx'
import ReportDate from '../ReportDate'
import ReportTable from './ReportTable'
import { createNewSheet } from '../Utils'

const DailyCounter = ({counterType}) => {
    const [reportDate, setReportDate] = useState(moment().subtract(1, "days"))
    const [reportData, setReportData] = useState([])
    const [totalsData, setTotalsData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const counterLabel = counterType[0].toUpperCase() + counterType.slice(1)

    const getReportDate = () => {
        setLoading(true)
        setErrorMsg(null)
        axios
            .get(`${process.env.REACT_APP_API_URL}/dailycounter?reportDate=${reportDate.format('YYYY-MM-DD')}&counterType=${counterType}`)
            .then(response => {
                setReportData(response.data.rows)
                setTotalsData(response.data.totals)
                setLoading(false)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })       
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#totalsData' })
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`Daily${counterLabel}BonusReport${reportDate.format('YYYY-MM-DD')}.pdf`)
    }

    const downloadXLSX = () => {
        const wb = XLSX.utils.book_new();

        // get the data from the table - as this will be the sorted version
        const reportTable = document.getElementById("reportData")
        const totalsTable = document.getElementById("totalsData")
        const ws = createNewSheet(totalsTable, reportTable)
        XLSX.utils.book_append_sheet(wb, ws, 'Totals')

        // set the column widths for the spreadsheet
        const wscols = [{wch:10}, {wch:10}, {wch:9}, {wch:9}, {wch:12}, {wch:10} ]
        ws['!cols'] = wscols
        // ws['!protect'] = true

        XLSX.writeFile(wb, `Daily${counterLabel}BonusReport${reportDate.format('YYYY-MM-DD')}.xlsx`)
    }

    const totalsColumns = useMemo(() => [
        {
            Header: `Daily ${counterLabel} Line Bonus Report for ${reportDate.format('MM/DD/YYYY')}`,
            columns: [
                {
                    Header: 'Total Count',
                    accessor: 'totalCount'
                },
                {
                    Header: 'Total Hours',
                    accessor: 'totalHrs'
                },
                {
                    Header: 'Average Count/Hr',
                    accessor: 'averageCount'
                },
                {
                    Header: 'Total Bonus',
                    accessor: 'totalBonus'
                },
                {
                    Header: 'Average Bonus',
                    accessor: 'averageBonus'
                }
            ],
            
        }
    ], [reportDate, counterLabel])

    const columns = useMemo(() => [
        {
            Header: 'Station No',
            accessor: 'station_id'
        },
        {
            Header: 'Employee Id',
            accessor: 'employee_id'
        },
        {
            Header: 'Work Hours',
            accessor: 'work_hrs'
        },
        {
            Header: 'Total Count',
            accessor: 'count'
        },
        {
            Header: 'Bonus Lobster',
            accessor: 'bonus_lobster'
        },
        {
            Header: 'Total Bonus',
            accessor: 'total_bonus',
            sortDescFirst: true
        }
    ], [])

    return (
        <div className="report">
            <h3>Daily {counterLabel} Line Bonus Report</h3>
            <div className="reportdate">
                <ReportDate reportDate={reportDate} updateReportDate={setReportDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getReportDate} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={totalsColumns} data={totalsData} tableId={"totalsData"}/>}
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"}/>}
            </div>
        </div>
    )
}

export default DailyCounter