import { useState, useMemo } from 'react'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {utils, writeFile} from 'xlsx'
import ReportDate from '../ReportDate'
import ReportTable from './ReportTable'
import { createNewSheet } from '../Utils'

const DailyGlobal = () => {
    const [reportDate, setReportDate] = useState(moment().subtract(1, "days"))
    const [reportData, setReportData] = useState([])
    const [bonusData, setBonusData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const getReportDate = () => {
        setLoading(true)
        setErrorMsg(null)
        axios
            .get(`${process.env.REACT_APP_API_URL}/dailyglobal?reportDate=${reportDate.format('YYYY-MM-DD')}`)
            .then(response => {
                setReportData(response.data.rows)
                setBonusData(response.data.bonusData)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
                setLoading(false)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })  
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#bonusData' })
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`DailyGlobalBonusReport${reportDate.format('YYYY-MM-DD')}.pdf`)
    }

    const downloadXLSX = () => {
        const wb = utils.book_new();

        // get the data from the table - as this will be the sorted version
        const bonusTable = document.getElementById("bonusData")
        const reportTable = document.getElementById("reportData")

        // const tempWs = XLSX.utils.table_to_sheet(bonusTable, {sheet: 'Totals'})
        // const tempWs2 =  XLSX.utils.table_to_sheet(reportTable, {sheet: 'Totals'})

        // // in order to remove the first merged cell from the table, we need to first convert the tempWs
        // // back to JSON, and then re-create the worksheet based on this json. This will remove
        // // the merged cell and allow for deletion
        // const ws_json = XLSX.utils.sheet_to_json(tempWs)
        // const ws_json2 = XLSX.utils.sheet_to_json(tempWs2)
        // const ws = XLSX.utils.json_to_sheet(ws_json, {sheet: 'Totals'})
        // XLSX.utils.sheet_add_json(ws, ws_json2, {origin: "A4"})
        const ws = createNewSheet(bonusTable, reportTable)
        utils.book_append_sheet(wb, ws, 'Totals')

        // set the column widths for the spreadsheet
        const wscols = [{wch:10}, {wch:10}, {wch:9}, {wch:9}, {wch:12}, {wch:10} ]
        ws['!cols'] = wscols
        // ws['!protect'] = true

        writeFile(wb, `DailyGlobalBonusReport${reportDate.format('YYYY-MM-DD')}.xlsx`)
    }

    const bonusColumns = useMemo(() => [
        {
            Header: `Daily Global Bonus Report for ${reportDate.format('MM/DD/YYYY')}`,
            columns: [
                {
                    Header: 'Total Lbs',
                    accessor: 'total_lbs'
                },
                {
                    Header: 'Global Bonus',
                    accessor: 'global_bonus'
                },
                {
                    Header: 'Meat Line Hours',
                    accessor: 'meat_line_tot_hrs'
                },
                {
                    Header: 'Butcher Vacuum Hours',
                    accessor: 'butch_vac_tot_hrs'
                },
                {
                    Header: 'Total Hours',
                    accessor: 'total_overall_hrs'
                },
                {
                    Header: 'Global Hours',
                    accessor: 'global_overall_hrs'
                },
                {
                    Header: 'Global Per Hour',
                    accessor: 'global_per_hour'
                }
            ],
            
        }
    ], [reportDate])

    const columns = useMemo(() => [
        {
            Header: 'Employee Id',
            accessor: 'employee_id'
        },
        {
            Header: 'Total Hours',
            accessor: 'total_hours'
        },
        {
            Header: 'Butcher / Vacuum Hours',
            accessor: 'butcher_vacuum_hrs'
        },
        {
            Header: 'Meat Line Hours',
            accessor: 'meat_hrs'
        },
        {
            Header: 'Global Hours',
            accessor: 'global_hrs'
        },
        {
            Header: 'Global Bonus',
            accessor: 'global_bonus'
        },
        {
            Header: 'Meat Bonus',
            accessor: 'meat_bonus'
        },
        {
            Header: 'Butcher / Vacuum Bonus',
            accessor: 'butcher_vacuum_bonus'
        },
        {
            Header: 'Total Bonus',
            accessor: 'total_bonus'
        }
    ], [])

    return (
        <div className="report">
            <h3>Daily Global Bonus Report</h3>
            <div className="reportdate">
                <ReportDate reportDate={reportDate} updateReportDate={setReportDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getReportDate} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={bonusColumns} data={bonusData} tableId={"bonusData"}/>}
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"} />}
            </div>
        </div>
    )
}

export default DailyGlobal