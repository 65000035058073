import { useState, useEffect, useRef } from 'react'

const ScaleItemAverage = ({scaleItemNo, scaleItem, setScaleData}) => {
    const [averageHours, setAverageHours] = useState(scaleItem.average_scale_hours)
    const [averageInd, setAverageInd] = useState(scaleItem.average_scale_hours ? true : false)
    const prevAverageHours = useRef()
    const prevAverageInd = useRef()

    const onAverageFieldChange = (e) => {
        setAverageHours(e.target.value)
        prevAverageHours.current = averageHours
    }

    const onAverageIndFieldChange = (e) => {
        setAverageInd(e.target.checked)
        prevAverageInd.current = averageInd
        if (!prevAverageInd.current) {
            setAverageHours(scaleItem.available_hours)
            prevAverageHours.current = averageHours
        } else {
            setAverageHours(null)
            prevAverageHours.current = averageHours
        }
    }

    useEffect(() => {
        if (averageHours || prevAverageHours.current) {
            const newScaleItem = scaleItem
            newScaleItem.override_hrs = averageHours
            setScaleData(scaleItemNo, newScaleItem)
        }
    }, [averageHours, scaleItem, scaleItemNo, setScaleData])

    return (
        <tr>
            <td>
                {scaleItem.employee_id}
            </td>
            <td>
                {scaleItem.ct_hours}
            </td>
            <td>
                {scaleItem.scale_hours || '-'}
            </td>
            <td>
                {scaleItem.butcher_hours || '-'}
            </td>
            <td>
                {scaleItem.vacuum_hours || '-'}
            </td>
            <td>
                {scaleItem.available_hours || '-'}
            </td>
            <td>
            <input 
                    type="checkbox"
                    value={averageInd || ''}
                    checked={averageInd}
                    disabled={scaleItem.available_hours === '0.00'}
                    onChange={e => onAverageIndFieldChange(e) } />
            </td>
            <td>
                <input 
                    type="number"
                    step="0.01"
                    min="0"
                    max={scaleItem.available_hours}
                    disabled={!averageInd}
                    className="text-input__scale"
                    name="averageWorkHours"
                    value={averageHours || ''}
                    onChange={e => onAverageFieldChange(e) } />
            </td>
        </tr>
    )
}

export default ScaleItemAverage