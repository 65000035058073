const ReferenceList = ({referenceData}) => {
    return (
        <div>
            <table className="styled-table styled-table__fixed">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {referenceData.map((referenceItem) => (
                        <tr key={referenceItem.code_type}>
                            <td>{referenceItem.code_short_desc}</td>
                            <td className="styled-table__wrap">{referenceItem.code_long_desc}</td>
                            <td>{referenceItem.code_value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ReferenceList