import { useState, useEffect, useRef } from 'react'

const DefaultStationList = ({defaultItemNo, defaultStationItem, setDefaultStationData}) => {
    const [newEmployeeId, setNewEmployeeId] = useState(defaultStationItem.employee_id)
    const prevEmployeeId = useRef()

    const onEmployeeIdFieldChange = (e) => {
        setNewEmployeeId(e.target.value)
        prevEmployeeId.current = newEmployeeId
    }

    useEffect(() => {
        if (newEmployeeId || prevEmployeeId) {
            const newDefaultStationItem = defaultStationItem
            newDefaultStationItem.employee_id = newEmployeeId
            setDefaultStationData(defaultItemNo, newDefaultStationItem)
        }
    }, [newEmployeeId])

    return (
        <tr>
            <td>
                {defaultStationItem.station_id}
            </td>
            <td>
                <input 
                    type="number"
                    min="0"
                    className="text-input__counter"
                    name="newEmployeeId"
                    value={newEmployeeId || ''}
                    onChange={e => onEmployeeIdFieldChange(e) } />
            </td>
        </tr>
    )
}

export default DefaultStationList