import { NavLink, Link} from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import logo from '../assets/images/pointe-sapin-invert-logo.png'
import { Auth } from 'aws-amplify'

const Header = () => (
    <header className="header">
        <div className="content-container">
            <div className="header__content">
                <Link to="/">
                    <img className="photo" src={logo} alt="Pointe-Sapin Seafoods logo" />
                </Link>
                <div className="xl__padding">
                    <NavLink to="/payroll-reports" activeClassName="header_title_active" className="header__title">Payroll Reports</NavLink>
                    <NavLink to="/prod-data" activeClassName="header_title_active" className="header__title" >Production Data</NavLink>
                    <NavLink to="/admin-panel" activeClassName="header_title_active" className="header__title" >Administrative Panel</NavLink>
                </div>
                <div className="button--group xl__padding">
                    <button className="button-invert" onClick={signOut}>Log Out</button>
                </div>
            </div>
        </div>
    </header>
)

async function signOut() {
    try {
        await Auth.signOut()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

export default Header