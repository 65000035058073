import { useState, useMemo } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import XLSX from 'xlsx'
import WeeklyReportDate from '../WeeklyReportDate'
import ReportTable from './ReportTable'
import { createNewSheet, deleteColumn, addColumn, deleteRow, addRow } from '../Utils'

const WeeklyPayroll= () => {
    const [reportDate, setReportDate] = useState(moment().day(-1))
    const [reportData, setReportData] = useState([])
    const [salaryData, setSalaryData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const getReportDate = async () => {
        setLoading(true)
        setErrorMsg(null)
        setReportData([])
        setSalaryData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()
        // console.log('JWT: ', jwt)
        // console.log('ID TOKEN: ', session.getIdToken())
        // console.log('Access TOKEN: ', session.getAccessToken().getJwtToken())
        // console.log('SESSION', session)
        axios
            .get(`${process.env.REACT_APP_API_URL}/weeklypayroll?reportDate=${reportDate.format('YYYY-MM-DD')}`,
            {headers: {Authorization: jwt}}
            )
            .then(response => {
                setReportData(response.data.rows)
                setSalaryData(response.data.salRows)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
                setLoading(false)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })  
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`WeeklySummaryReport${reportDate.format('YYYY-MM-DD')}.pdf`)
    }

    const downloadXLSX = () => {
        const wb = XLSX.utils.book_new();

        // get the data from the table - as this will be the sorted version
        const table = document.getElementById("reportData")
        const ws = createNewSheet(table)
        XLSX.utils.book_append_sheet(wb, ws, 'PS Payroll')

        deleteColumn(ws, 0)
        addColumn(ws, 10, 'Supplies')
        addColumn(ws, 11, 'Emp Adv')

        const startDate = moment(reportDate).subtract(6, 'd')
        addRow(ws, 0, 2)
        addRow(ws, 0, 1, 'Week Ended', reportDate)
        addRow(ws, 0, 1, `Payroll ${reportDate.format('YYYY')}`, startDate)
        addRow(ws, 0, 1, 'Pointe Sapin')

        ws["!merges"] = [{s: {r: 0, c: 0}, e: {r: 0, c: 1}},{s: {r: 1, c: 0}, e: {r: 1, c: 1}},{s: {r: 2, c: 0}, e: {r: 2, c: 1}}]

        // add explicit formulas to spreasdheet
        let range = XLSX.utils.decode_range(ws['!ref'])
        for (let rowNum = range.s.r + 6; rowNum < range.e.r; rowNum++) {
            // console.log(reportData[rowNum-1])
            // get the all in vacation rate as a formula 
            ws[XLSX.utils.encode_cell({r: rowNum, c:5})] = {t: 'n', z: '0.00', f:`ROUND(D${rowNum + 1}*E${rowNum + 1},2)`}

            // determine the reg hours - if the reg rate is > $19.15, use actuals, otherwise use 44 unless actual is less than 44, then use actual
            ws[XLSX.utils.encode_cell({r: rowNum, c:7})] = {t: 'n', z: '0.00', f:`=IF(D${rowNum + 1} > ${reportData[rowNum-6].ot_wage}, G${rowNum + 1}, IF(G${rowNum + 1}>44, 44, G${rowNum + 1}))`}

            // determine the ot hours - actual hrs minus ot hrs
            ws[XLSX.utils.encode_cell({r: rowNum, c:8})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=MAX(0, G${rowNum + 1} - H${rowNum + 1})`}

            // get the total pay 
            ws[XLSX.utils.encode_cell({r: rowNum, c:9})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', 
                f:`=(D${rowNum + 1} * E${rowNum + 1} * H${rowNum + 1}) + (${reportData[rowNum-6].ot_wage} * E${rowNum + 1} * I${rowNum + 1})`}

            // format prod bonus
            ws[XLSX.utils.encode_cell({r: rowNum, c:12})].z = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

            // format global bonus
            ws[XLSX.utils.encode_cell({r: rowNum, c:13})].z = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

            // get the gross pay with bonus 
            ws[XLSX.utils.encode_cell({r: rowNum, c:14})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', 
                f:`=(J${rowNum + 1}) + (M${rowNum + 1} * E${rowNum + 1}) + (N${rowNum + 1} * E${rowNum + 1})`}
        }

        // set the formula for the totals row
        ws[XLSX.utils.encode_cell({r:range.e.r, c:6})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(G7:G${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:7})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(H7:H${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:8})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(I7:I${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:9})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(J7:J${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:10})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(K7:K${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:11})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(L7:L${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:12})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(M7:M${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:13})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(N7:N${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:14})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(O7:O${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:15})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(P7:P${range.e.r})`}
        ws[XLSX.utils.encode_cell({r:range.e.r, c:16})] = {t: 'n', z: '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-', f:`=SUM(Q7:Q${range.e.r})`}

        // set the column widths for the spreadsheet
        const wscols = [{wch:8}, {wch:15}, {wch:15}, {wch:10}, {wch:8}, {wch:10}, {wch:10}, {wch:10}, {wch:10}, {wch:13}, {wch:10}, {wch:10}, {wch:10}, {wch:10}, {wch:13}, {wch:10} ]
        ws['!cols'] = wscols
        // ws['!protect'] = true

        if (salaryData.length > 0) {
            const salTable = document.getElementById("salaryData")
            const salaryWs = XLSX.utils.table_to_sheet(salTable, {sheet: 'PS Payroll'})
            const salaryJson = XLSX.utils.sheet_to_json(salaryWs)
            console.log(salaryJson)
            console.log('RANGE: ', range)
            XLSX.utils.sheet_add_json(ws, salaryJson, {origin: `B${range.e.r+3}`})
            // deleteRow(ws, range.e.r+2)
            deleteRow(ws, range.e.r+2)
            deleteRow(ws, range.e.r+2)
        }

        XLSX.writeFile(wb, `WeeklyPayrollReport${reportDate.format('YYYY-MM-DD')}.xlsx`)
    }

    const columns = useMemo(() => [
        {
            Header: `Weekly Summary Bonus Report for week ending ${reportDate.format('MM/DD/YYYY')}`,
            id: 'MainHeader',
            columns: [
                {
                    Header: 'Employee Id',
                    accessor: 'employee_id'
                },
                {
                    Header: 'Payroll Id',
                    accessor: 'payroll_id'
                },
                {
                    Header: 'Last Name',
                    accessor: 'last_name'
                },
                {
                    Header: 'First Name',
                    accessor: 'first_name',
                    Footer: 'TOTALS'
                },
                {
                    Header: 'Rate',
                    accessor: 'reg_wage'
                    // Cell: ({row}) => Number(row.original.reg_wage).toFixed(4)
                },
                {
                    Header: 'Vac',
                    Cell: 1.04
                },
                {
                    Header: 'Rate + VP',
                    Cell: ({row}) => Number(row.original.reg_wage * 1.04).toFixed(2)
                },
                {
                    Header: 'Total Hours',
                    accessor: 'weekly_hrs',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.weekly_hrs) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },
                {
                    Header: 'Reg Hours',
                    accessor: 'reg_hrs',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.reg_hrs) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },
                {
                    Header: 'OT Hours',
                    Cell: ({row}) => (Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs))).toFixed(2)),
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs))).toFixed(2)) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },
                {
                    Header: 'Total Pay',
                    Cell: ({row}) => Number(( Number(row.original.reg_hrs) * 1.04 * Number(row.original.reg_wage)) + 
                        ((Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs)).toFixed(2))) * Number(row.original.ot_wage))).toFixed(2),
                        Footer: data => {
                            const total = useMemo(() =>
                                data.rows.reduce((sum, row) => Number(Number(( Number(row.original.reg_hrs) * 1.04 * Number(row.original.reg_wage)) + 
                                ((Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs)).toFixed(2))) * Number(row.original.ot_wage))).toFixed(2)) + sum,0), [data.rows]
                            )
                            return <>{total.toFixed(2)}</>
                        }
                },
                {
                    Header: 'Prod. Bonus',
                    accessor: 'weekly_production_bonus',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.weekly_production_bonus) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },                
                {
                    Header: 'Global Bonus',
                    accessor: 'weekly_global_bonus',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.weekly_global_bonus) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },
                {
                    Header: 'Gross Pay',
                    Cell: ({row}) => Number(( Number(row.original.reg_hrs) * 1.04 * Number(row.original.reg_wage)) + 
                        ((Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs)).toFixed(2))) * (Number(row.original.ot_wage) * 1.04)) + 
                        ( (Number(row.original.weekly_production_bonus) + Number(row.original.weekly_global_bonus)) * 1.04) ).toFixed(2) ,
                        Footer: data => {
                            const total = useMemo(() =>
                                data.rows.reduce((sum, row) => Number(Number(( Number(row.original.reg_hrs) * 1.04 * Number(row.original.reg_wage)) + 
                                ((Math.max(0, (Number(row.original.weekly_hrs) - Number(row.original.reg_hrs)).toFixed(2))) * (Number(row.original.ot_wage) * 1.04)) + 
                                ( (Number(row.original.weekly_production_bonus) + Number(row.original.weekly_global_bonus)) * 1.04) ).toFixed(2)) + sum,0), [data.rows]
                            )
                            return <>{total.toFixed(2)}</>
                        }
                },
                {
                    Header: 'Rent',
                    accessor: 'rent_amt',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.rent_amt) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                },
                {
                    Header: 'Transportation',
                    accessor: 'transport_amt',
                    Footer: data => {
                        const total = useMemo(() =>
                            data.rows.reduce((sum, row) => Number(row.values.transport_amt) + sum,0), [data.rows]
                        )
                        return <>{total.toFixed(2)}</>
                    }
                }
            ],
            Footer: ''
            
        }
    ], [reportDate])

    return (
        <div className="report">
            <h3>Weekly Payroll Report</h3>
            <div className="reportdate">
                <WeeklyReportDate reportDate={reportDate} updateReportDate={setReportDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getReportDate} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"} showFooter={"true"}/>}
            </div>
            <div className="table-layout">
                {salaryData.length > 0 && 
                <table className="styled-table" id="salaryData">
                    <thead>
                        <tr>
                            <th colSpan="3">Salaried Employees</th>
                        </tr>
                        <tr>
                            <th>Last Name</th>
                            <th>First Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {salaryData.map((salaryItem, index) => (
                        <tr key={index}>
                            <td>{salaryItem.last_name}</td>
                            <td>{salaryItem.first_name}</td>
                            <td>Salary</td>
                        </tr>
                    ))}
                </tbody>
                </table>}
            </div>
        </div>
    )
}

export default WeeklyPayroll
