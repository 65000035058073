import EmployeeRoster from './admin-panel/EmployeeRoster'
import ReferenceData from './admin-panel/ReferenceData'
import DefaultStation from './admin-panel/DefaultStation'
import EmployeeEdit from './admin-panel/EmployeeEdit'

const AdministrativePanelOptions = [
    {
        title: 'Employee Roster',
        path: '/admin-panel/EmployeeRoster',
        component: EmployeeRoster,
        authorized: 'payroll-group'
    },
    {
        title: 'Reference Data',
        path: '/admin-panel/ReferenceData',
        component: ReferenceData,
        authorized: 'user-group'
    },
    {
        title: 'Employee Default Station',
        path: '/admin-panel/DefaultStation',
        component: DefaultStation,
        authorized: 'user-group'
    },
    {
        title: 'Employee Detail Edit',
        path: '/admin-panel/EmployeeEdit/:id',
        component: EmployeeEdit,
        visibility: 'hidden'
    },
    {
        title: 'Employee Add',
        path: '/admin-panel/EmployeeEdit',
        component: EmployeeEdit,
        visibility: 'hidden'
    }
]

export default AdministrativePanelOptions