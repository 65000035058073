import React from 'react'

export default class CounterItem extends React.Component {

    addNewRow = () => {
        this.props.addCounterItemRow(this.props.counterItemNo, this.props.counterItem)
    }

    render () {
        return (
            <tr>
                <td>
                    <button type="button" className="table-button__small" onClick={this.addNewRow} disabled={this.props.counterItem.empty_ind}>+</button>{this.props.counterItem.station_id}
                </td>
                <td>
                    {this.props.counterItem.empty_ind && 0}
                    {!this.props.counterItem.empty_ind  &&
                    <input 
                        type="number"
                        min="0"
                        className="text-input__scale"
                        name="count"
                        disabled={this.props.counterItem.empty_ind}
                        value={this.props.counterItem.count || ''} 
                        onChange={e => this.props.onNumberFieldChange(this.props.counterItemNo, this.props.counterItem, e.target.value, "count") } />
                    }
                </td>
                <td>
                    {this.props.counterItem.empty_ind && '-'}
                    {!this.props.counterItem.empty_ind &&
                    <input 
                        type="number"
                        min="1"
                        className="text-input__counter"
                        name="overrideEmployeeId"
                        disabled={this.props.counterItem.empty_ind}
                        value={this.props.counterItem.employee_id || ''}
                        onChange={e => this.props.onNumberFieldChange(this.props.counterItemNo, this.props.counterItem, e.target.value, "employee_id") } />
                    }
                </td>
                <td>
                    <input 
                        type="checkbox"
                        value={this.props.counterItem.empty_ind || ''}
                        checked={this.props.counterItem.empty_ind}
                        onChange={e => this.props.onEmptyFieldChange(this.props.counterItemNo, this.props.counterItem, e.target.checked) }/>
                </td>
                <td>
                    {this.props.counterItem.empty_ind && '-'}
                    {!this.props.counterItem.empty_ind &&
                    <input 
                        type="time"
                        className="text-input__time"
                        name="startTime"
                        disabled={this.props.counterItem.empty_ind}
                        value={this.props.counterItem.start_time || ''}
                        onChange={e => this.props.onTimeFieldChange(this.props.counterItemNo, this.props.counterItem, e.target.value, "start_time") } />
                    }
                </td>
                <td>
                    {this.props.counterItem.empty_ind && '-'}
                    {!this.props.counterItem.empty_ind &&
                    <input 
                        type="time"
                        className="text-input__time"
                        name="endTime"
                        disabled={this.props.counterItem.empty_ind}
                        value={this.props.counterItem.end_time || ''}
                        onChange={e => this.props.onTimeFieldChange(this.props.counterItemNo, this.props.counterItem, e.target.value, "end_time") } />
                    }
                </td>
                <td>
                    {this.props.counterItem.empty_ind && '-'}
                    {!this.props.counterItem.empty_ind && this.props.counterItem.work_hrs}
                </td>
            </tr>
        )
    }
}
