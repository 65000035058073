import { useState } from 'react'
import {Auth} from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import ReportDate from '../ReportDate'
import GradedTailItem from "./GradedTailItem"

const GradedTailData = () => {
    const [productionDate, setProductionDate] = useState(moment().subtract(1, "days"))
    const [gradedTailData, setGradedTailData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const getProductionData = async (e) => {
        e.preventDefault()

        setErrorMsg('')
        setLoading(true)
        setGradedTailData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .get(`${process.env.REACT_APP_API_URL}/gradedtaildata?productionDate=${productionDate.format('YYYY-MM-DD')}`, 
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            if (response.data.count === 0) {
                setErrorMsg('No Data Found')
            }
            console.log(response.data)
            setGradedTailData(response.data.rows)
            setLoading(false)
            console.log(gradedTailData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        // console.log(scaleData)    
        const overrideRows = gradedTailData.filter(gradedTailItem => gradedTailItem.override_graded_tail_id)
            .map(({id, override_graded_tail_id}) => ({id, override_graded_tail_id}))
        console.log(overrideRows)        

        setErrorMsg('')
        setSaving(true)
        console.log(overrideRows)
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()

        axios
        .put(`${process.env.REACT_APP_API_URL}/gradedtaildata`, {productionDate: productionDate.format('YYYY-MM-DD'), overrideRows}, 
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setSaving(false)

        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem saving data: ', error)
            setSaving(false)
        })            
    }

    const updateGradedTailData = (index, gradedTailItem) => {
      let newGradedTailData = gradedTailData
      newGradedTailData[index] = gradedTailItem
      setGradedTailData(newGradedTailData)
  }    

    // const onGradedTailIdChange = (index, gradedTailItem, value, name) => {
    //     let newGradedTailData = gradedTailData
    //     let newGradedTailItem = gradedTailItem
    //     newGradedTailItem[name] = Number(value)
    //     newCounterData[index] = newCounterItem
    //     setCounterData(newCounterData.map(newCounterItem => {
    //         return newCounterItem
    //     }))
    // }

    return (
        <div className="report">
            <h3>Daily Graded Tails Production</h3>
            <div className="reportdate">
                <ReportDate reportDate={productionDate} updateReportDate={setProductionDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getProductionData} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            {gradedTailData.length > 0 &&
            <div className="table-layout">
                <form onSubmit={onSubmit}>
                    <div className="reportdate__button formdata__padding">
                        <button className="button" disabled={loading || saving}>Save Graded Tails Data</button>
                    </div>
                        { saving && <h3>Saving Data...</h3>}
                        <table className="styled-table">
                          <thead>
                              <tr>
                                  <th>Weigh Time</th>
                                  <th>Weight</th>
                                  <th>Graded Tail Id</th>
                                  <th>Description</th>
                                  <th></th>
                                  <th>Override Graded Tail Id</th>
                              </tr>
                          </thead>
                          <tbody>
                              {gradedTailData.map((gradedTailItem, index) => (
                                  <GradedTailItem key={index} 
                                      gradedTailItemNo={index} 
                                      gradedTailItem={gradedTailItem} 
                                      setGradedTailData={updateGradedTailData} 
                                  />
                              ))}
                          </tbody>
                        </table>
                        <div className="reportdate__button formdata">
                            <button className="button" disabled={loading || saving}>Save Graded Tail Data</button>
                        </div>
                    { saving && <h3>Saving Data...</h3>}
                </form>
            </div>
            }
        </div>
    )
}

export default GradedTailData