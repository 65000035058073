import { useState, useEffect } from 'react'
import {Auth} from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

const EmployeeRoster = () => {
    const currentDate = moment()
    const [employeeData, setEmployeeData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getEmployeeData()
    }, [])

    const getEmployeeData = async () => {
        setErrorMsg('')
        setLoading(true)
        setEmployeeData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()
        axios
        .get(`${process.env.REACT_APP_API_URL}/employees?currentDate=${currentDate.format('YYYY-MM-DD')}`, 
        {headers: {Authorization: jwt}}
        )
        .then(response => {
            setEmployeeData(response.data.employeeRows)
            setLoading(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    return (
        <div className="report">
            <h3>Employee Roster - {currentDate.format('MM/DD/YYYY')}</h3>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="reportdate__button">
                <NavLink to={`/admin-panel/EmployeeEdit`}>
                    <button className="button" type="submit" disabled={loading}>Add New Employee</button>
                </NavLink>
            </div>
            <div>
                <table className="styled-table styled-table__fixed">
                    <thead>
                        <tr>
                            <th>Employee Id</th>
                            <th>Payroll Id</th>
                            <th>Last Name</th>
                            <th>First Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {employeeData && employeeData.map((employeeItem) => (
                        <tr key={employeeItem.employee_id}>
                            <td>{employeeItem.employee_id}</td>
                            <td>{employeeItem.payroll_employee_id}</td>
                            <td>{employeeItem.last_name}</td>
                            <td>{employeeItem.first_name}</td>
                            <td>
                            <NavLink to={`/admin-panel/EmployeeEdit/${employeeItem.employee_id}`}>Edit</NavLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmployeeRoster