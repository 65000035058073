import { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import ReportDate from '../ReportDate'
import CounterItem from "./CounterItem"

const CounterData = ({counterType}) => {
    const [productionDate, setProductionDate] = useState(moment().subtract(1, "days"))
    const [counterData, setCounterData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [create, setCreate] = useState(false)

    const counterLabel = counterType[0].toUpperCase() + counterType.slice(1)

    const getProductionData = (e) => {
        e.preventDefault()

        setErrorMsg('')
        setLoading(true)
        setCreate(false)
        setCounterData([])
        axios
        .get(`${process.env.REACT_APP_API_URL}/counterdata?productionDate=${productionDate.format('YYYY-MM-DD')}&counterType=${counterType}`)
        .then(response => {
            if (response.data.count === 0) {
                setErrorMsg('No Data Found')
                setCreate(true)
            }
            console.log(response.data)
            setCounterData(response.data.rows)
            setLoading(false)
            console.log(counterData)
        })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const onSubmit = (e) => {
        e.preventDefault()

        setErrorMsg('')
        setSaving(true)
        console.log(counterData)
        axios
            .put(`${process.env.REACT_APP_API_URL}/counterdata`, {productionDate: productionDate.format('YYYY-MM-DD'), counterType, counterRows: counterData} )
            .then(response => {
                setCounterData(response.data.rows.map(newCounterItem => {
                    return newCounterItem
                }))
                setSaving(false)

            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem saving data: ', error)
                setSaving(false)
            })            
    }

    const createEmptyData = () => {
        setCreate(false)
        setErrorMsg('')
        const emptyRow = {id: null, count: 0, employee_id: null, empty_ind: false}
        let newCounterData = counterData
        for (let i = 1; i <= 24; i++) {
            newCounterData.push({...emptyRow, station_id: i})
        }
        setCounterData(newCounterData.map(newCounterItem => {
            return newCounterItem
        }))
    }

    const copyShiftData = (e) => {
        setCounterData(counterData.map(counterItem => {
            let newCounterItem = counterItem
            if (!counterItem.empty_ind) {
                console.log('hello')
                newCounterItem.start_time = document.getElementById("shiftStartTime").value
                newCounterItem.end_time = document.getElementById("shiftEndTime").value
                let workHours = 0
                if (newCounterItem.start_time && newCounterItem.end_time) {
                    const momentStart = moment(counterItem.start_time, 'hh:mm:ss a')
                    const momentEnd = moment(counterItem.end_time, 'hh:mm:ss a')
                    workHours = (Number(momentEnd.diff(momentStart)/3600000).toFixed(2))
                }
                newCounterItem.work_hrs = workHours
            }
            return newCounterItem
        }))
    }

    const addCounterItemRow = (index, counterItem) => {
        let newCounterData = counterData
        newCounterData.splice(index + 1, 0, {id: null, station_id: counterItem.station_id, count: 0, employee_id: null, empty_ind: false})
        setCounterData(newCounterData.map(newCounterItem => {
            return newCounterItem
        }))
    }

    const onNumberFieldChange = (index, counterItem, value, name) => {
        let newCounterData = counterData
        let newCounterItem = counterItem
        newCounterItem[name] = Number(value)
        newCounterData[index] = newCounterItem
        setCounterData(newCounterData.map(newCounterItem => {
            return newCounterItem
        }))
    }

    const onTimeFieldChange = (index, counterItem, value, name) => {
        let newCounterData = counterData
        let newCounterItem = counterItem
        newCounterItem[name] = value
        let workHours = 0
        if (newCounterItem.start_time && newCounterItem.end_time) {
            const momentStart = moment(counterItem.start_time, 'hh:mm:ss a')
            const momentEnd = moment(counterItem.end_time, 'hh:mm:ss a')
            workHours = (Number(momentEnd.diff(momentStart)/3600000).toFixed(2))
        }
        newCounterItem.work_hrs = workHours
        newCounterData[index] = newCounterItem
        setCounterData(newCounterData.map(newCounterItem => {
            return newCounterItem
        }))
    }

    const onEmptyFieldChange = (index, counterItem, checked ) => {
        let newCounterData = counterData
        let newCounterItem = counterItem
        newCounterItem.empty_ind = checked
        newCounterData[index] = newCounterItem
        setCounterData(newCounterData.map(newCounterItem => {
            return newCounterItem
        }))
    }

    return (
        <div className="report">
            <h3>Daily {counterLabel} Production</h3>
            <div className="reportdate">
                <ReportDate reportDate={productionDate} updateReportDate={setProductionDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getProductionData} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            {create && <button className="button" onClick={createEmptyData}>Create Empty Rows</button>}
            {counterData.length > 0 &&
            <div className="table-layout">
                <div className="formdata__padding reportdate__button">
                    <label className="form">Shift Hours: </label>
                    <input 
                        type="time"
                        className="text-input__time text-input__margin"
                        name="shiftStartTime" 
                        id="shiftStartTime"
                        />
                    <input 
                        type="time"
                        className="text-input__time text-input__margin"
                        name="shiftEndTime" 
                        id="shiftEndTime"/>
                    <button className="button text-input__margin" disabled={loading} onClick={copyShiftData}>Apply Shift</button>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="reportdate__button formdata__padding">
                        <button className="button" disabled={loading}>Save {counterLabel} Data</button>
                    </div>
                        { saving && <h3>Saving Data...</h3>}
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Station No.</th>
                                    <th>Count</th>
                                    <th>Employee Id</th>
                                    <th>Empty?</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Work Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                {counterData.map((counterItem, index) => (
                                    <CounterItem key={index} 
                                        counterItemNo={index} 
                                        counterItem={counterItem} 
                                        onNumberFieldChange={onNumberFieldChange}
                                        onTimeFieldChange={onTimeFieldChange}
                                        onEmptyFieldChange={onEmptyFieldChange}
                                        addCounterItemRow={addCounterItemRow} 
                                    />
                                ))}
                            </tbody>
                        </table>
                        <div className="reportdate__button formdata">
                            <button className="button" disabled={loading}>Save {counterLabel} Data</button>
                        </div>
                    { saving && <h3>Saving Data...</h3>}
                </form>
            </div>
            }
        </div>
    )
}

export default CounterData