import { useState, useMemo } from 'react'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import XLSX from 'xlsx'
import ReportDate from '../ReportDate'
import ReportTable from './ReportTable'
import { createNewSheet } from '../Utils'

const DailyMeat = () => {
    const [reportDate, setReportDate] = useState(moment().subtract(1, "days"))
    const [reportData, setReportData] = useState([])
    const [totalsData, setTotalsData] = useState([])
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const getReportData = () => {
        setErrorMsg(null)
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_URL}/dailymeatline?reportDate=${reportDate.format('YYYY-MM-DD')}`)
            .then(response => {
                setReportData(response.data.rows)
                setTotalsData(response.data.totals)
                setLoading(false)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })   
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#totalsData' })
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`DailyMeatBonusReport${reportDate.format('YYYY-MM-DD')}.pdf`)
    }

    const downloadXLSX = () => {
        const wb = XLSX.utils.book_new();

        // get the data from the table - as this will be the sorted version
        const reportTable = document.getElementById("reportData")
        const totalsTable = document.getElementById("totalsData")
        const ws = createNewSheet(totalsTable, reportTable)
        XLSX.utils.book_append_sheet(wb, ws, 'Totals')

        // // add explicit formulas to spreasdheet
        // let range = XLSX.utils.decode_range(ws['!ref'])
        // for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        //     // update row for average lbs/hr (column f/5) to use a formula
        //     ws[XLSX.utils.encode_cell({r: rowNum, c:5})] = {t: 'n', z: '0.00', f:`ROUND(B${rowNum + 1}/E${rowNum + 1},2)`}

        //     // update row for Total Bonus to use formula
        //     ws[XLSX.utils.encode_cell({r: rowNum, c:6})] = {t: 'n', z: '$0.00', f:`MAX(0,ROUND(E${rowNum + 1} * (F${rowNum + 1} - 8) * 0.5,2))`}
        // }

        // set the column widths for the spreadsheet
        const wscols = [{wch:12}, {wch:12}, {wch:12}, {wch:12}, {wch:11}, {wch:13}, {wch:10} ]
        ws['!cols'] = wscols
        // ws['!protect'] = true

        XLSX.writeFile(wb, `DailyMeatBonusReport${reportDate.format('YYYY-MM-DD')}.xlsx`)
    }

    const totalsColumns = useMemo(() => [
        {
            Header: `Daily Meat Line Bonus Report for ${reportDate.format('MM/DD/YYYY')}`,
            columns: [
                {
                    Header: 'Total Weight',
                    accessor: 'totalWeight'
                },
                {
                    Header: 'Tot Scale Hrs',
                    accessor: 'totalHrs'
                },
                {
                    Header: 'Tot Hrs for Avg',
                    accessor: 'totalAvgHrs'
                },
                {
                    Header: 'Total Bonus',
                    accessor: 'totalBonus'
                },
                {
                    Header: 'Average Lbs/Hr',
                    accessor: 'totalAvgLbs'
                },
                {
                    Header: 'Average Bonus',
                    accessor: 'averageBonus'
                }
            ],
            
        }
    ], [reportDate])

    const columns = useMemo(() => [
        {
            Header: 'Employee Id',
            accessor: 'employee_id'
        },
        {
            Header: 'Total Weight',
            accessor: row => {
                if (row.average_ind) {
                    return '-'
                } else {
                    return row.total_weight
                }
            },
            // accessor: 'total_weight',
            sortDescFirst: true
        },
        {
            Header: 'Start Time',
            accessor: row => {
                if (row.average_ind) {
                    return '-'
                } else {
                    return row.start_time
                }
            }
            // accessor: 'start_time'
        },
        {
            Header: 'End Time',
            accessor: row => {
                if (row.average_ind) {
                    return '-'
                } else {
                    return row.end_time
                }
            }
            // accessor: 'end_time'
        },
        {
            Header: 'Work Hours',
            accessor: 'work_hrs'
            // accessor: d => (
            //     <div>
            //         {d.work_hrs} {d.override_ind && (d.override_ind)}
            //     </div>
            // )
        },
        {
            Header: 'Ovr/Avg',
            // accessor: 'override_ind',
            accessor: row => {
                if (row.override_ind) {
                    return 'O'
                } else if (row.average_ind) {
                    return 'A'
                } else {
                    return
                }
            },
            sortDescFirst: true
        },
        {
            Header: 'Average Lbs/Hr',
            accessor: 'avg_lbs',
            sortDescFirst: true
        },
        {
            Header: 'Total Bonus',
            accessor: 'total_bonus',
            sortDescFirst: true
        }
    ], [])

    return (
        <div className="report">
            <h3>Daily Meat Line Bonus Report</h3>
            <div className="reportdate">
                <ReportDate reportDate={reportDate} updateReportDate={setReportDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getReportData} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={totalsColumns} data={totalsData} tableId={"totalsData"}/>}
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"}/>}
            </div>
        </div>
    )
}

export default DailyMeat