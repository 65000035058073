import XLSX from 'xlsx'

const ec = (r,c) => {
    return XLSX.utils.encode_cell({r:r, c:c})
}

export const addRow = (ws, row_index, num_rows, firstValue, secondValue) => {
    let variable = XLSX.utils.decode_range(ws['!ref'])
    for (let R = variable.e.r; R >= row_index; --R) {
        for (let C = variable.s.c; C <= variable.e.c; ++C) {
            ws[ec(R+num_rows,C)] = ws[ec(R,C)]
            if (C === 0 && firstValue) {
                ws[ec(R,C)] = {v: firstValue}
            } else if (C == 2 && secondValue) {
                ws[ec(R,C)] = {t: 'd', z: 'd-mmm-yy', v: secondValue}
            } else {
                ws[ec(R,C)] = {v: ''}
            }
        }
    }
    variable.e.r+=num_rows
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e)
}

export const deleteRow = (ws, row_index) => {
    let variable = XLSX.utils.decode_range(ws['!ref'])
    for (let R = row_index; R < variable.e.r; ++R) {
        for (let C = variable.s.c; C <= variable.e.c; ++C) {
            ws[ec(R,C)] = ws[ec(R+1,C)]
        }
    }
    variable.e.r--
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e)
}

export const deleteColumn = (ws, col_index) => {
    let variable = XLSX.utils.decode_range(ws['!ref'])
    for (let C = col_index; C < variable.e.c; ++C) {
        for (let R = variable.s.r; R <= variable.e.r; ++R) {
            ws[ec(R,C)] = ws[ec(R,C+1)]
        }
    }
    variable.e.c--
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e)
}

export const addColumn = (ws, col_index, colHeader) => {
    let variable = XLSX.utils.decode_range(ws['!ref'])
    for (let C = variable.e.c; C >= col_index; --C) {
        for (let R = variable.s.r; R <= variable.e.r; ++R) {
            ws[ec(R,C+1)] = ws[ec(R,C)]
            if (R===0) {
                ws[ec(R,C)] = {v: colHeader}
            } else {
                ws[ec(R,C)] = {v: ''}
            }
        }
    }
    variable.e.c++
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e)
}

export const createNewSheet = (table, optionalTable) => {
    // create a temporary sheet based on the table as this is the sorted version
    const tempWs = XLSX.utils.table_to_sheet(table, {sheet: 'Totals'})

    // in order to remove the first merged cell from the table, we need to first convert the tempWs
    // back to JSON, and then re-create the worksheet based on this json. This will remove
    // the merged cell and allow for deletion
    const wsJson = XLSX.utils.sheet_to_json(tempWs)
    const ws = XLSX.utils.json_to_sheet(wsJson, {sheet: 'Totals'})

    if (optionalTable) {
        const optionalWs = XLSX.utils.table_to_sheet(optionalTable, {sheet: 'Totals'})
        const optionalJson = XLSX.utils.sheet_to_json(optionalWs)
        XLSX.utils.sheet_add_json(ws, optionalJson, {origin: "A5"})
    }
    deleteRow(ws, 0)
    return ws
}

