import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

const EmployeeItem = ({employeeItem, wageCodeData, deductCodeData, setEmployeeData}) => {
    const [newCodeValue, setNewCodeValue] = useState('')
    const [editCodeValue, setEditCodeValue] = useState(false)
    const [newPayrollId, setNewPayrollId] = useState(employeeItem.payroll_employee_id)
    const [newFirstName, setNewFirstName] = useState(employeeItem.first_name)
    const [newLastName, setNewLastName] = useState(employeeItem.last_name)
    const [newWageCode, setNewWageCode] = useState(employeeItem.wage_type_code)
    const [newRegWageAmt, setNewRegWageAmt] = useState(employeeItem.ovr_reg_wage_amt)
    const [newRentCode, setNewRentCode] = useState(employeeItem.rent_deduction_code)
    const [newRentAmt, setNewRentAmt] = useState(employeeItem.rent_deduction_amt)
    const [newTransportCode, setNewTransportCode] = useState(employeeItem.transportation_deduction_code)
    const [newTransportAmt, setNewTransportAmt] = useState(employeeItem.transportation_deduction_amt)
    const [buttonLabel, setButtonLabel] = useState('Edit')
    const [saving, setSaving] = useState(false)
    const prevCodeValue = useRef()

    useEffect(() => {
        setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
    }, [editCodeValue])

    const onEditClick = () => {
        setEditCodeValue(!editCodeValue)
        if (!editCodeValue) {
            setEditCodeValue(employeeItem.employee_id)
        }
    }
    useEffect(() => {
        if (newWageCode !== 'NST') {
            setNewRegWageAmt(null)
        }
    }, [newWageCode])

    useEffect(() => {
        if (newRentCode !== 'NST') {
            setNewRentAmt(null)
        }
    }, [newRentCode])

    useEffect(() => {
        if (newTransportCode !== 'NST') {
            setNewTransportAmt(null)
        }
    }, [newTransportCode])

    const saveCodeValue = (e) => {
        e.preventDefault()

        setSaving(true)

        const updateType = 'update'
        axios
            .put(`${process.env.REACT_APP_API_URL}/employeedetail`, {employeeId: employeeItem.employee_id, startDate: employeeItem.start_date, endDate: employeeItem.end_date,
                payrollId: newPayrollId, firstName: newFirstName, lastName: newLastName, wageCode: newWageCode, regWageAmt: newRegWageAmt, 
                rentCode: newRentCode, rentAmt: newRentAmt, transportCode: newTransportCode, transportAmt: newTransportAmt, updateType} )
            .then(response => {
                setSaving(false)
                onEditClick()
                setEmployeeData(response.data.employeeRows)
            }) 
            .catch(error => {
                console.log('The error is: ', error)
                setSaving(false)
            })                 
    }

    return (
        <tr key={employeeItem.start_date}>
            <td>{employeeItem.start_date}</td>
            <td>{employeeItem.end_date}</td>
            <td>
                {!editCodeValue &&
                    employeeItem.payroll_employee_id
                }
                {editCodeValue &&
                <input 
                    type="number"
                    className="text-input__payroll"
                    name="newPayrollId"
                    value={newPayrollId || ''}
                    onChange={e => setNewPayrollId(e.target.value) } 
                />}
            </td>
            <td>
                {!editCodeValue &&
                    employeeItem.first_name
                }
                {editCodeValue &&
                <input 
                    type="text"
                    className="text-input__name"
                    name="newFirstName"
                    value={newFirstName || ''}
                    onChange={e => setNewFirstName(e.target.value) } 
                />}
            </td>
            <td>
                {!editCodeValue &&
                    employeeItem.last_name
                }
                {editCodeValue &&
                <input 
                    type="text"
                    className="text-input__name"
                    name="newLastName"
                    value={newLastName || ''}
                    onChange={e => setNewLastName(e.target.value) } 
                />}
            </td>
            <td>                
                {!editCodeValue &&
                    employeeItem.wage_type_desc
                }
                {editCodeValue && 
                    <select className="select__table" value={newWageCode} onChange={e => setNewWageCode(e.target.value)}>
                        {wageCodeData.map((wageCodeItem) => (
                            <option key={wageCodeItem.wage_type_code} value={wageCodeItem.wage_type_code}>{wageCodeItem.wage_type_desc}</option>
                        ))}
                    </select>
                }
            </td>
            <td>
                {!editCodeValue && employeeItem.wage_type_code === 'NST' && employeeItem.ovr_reg_wage_amt}
                {!editCodeValue && employeeItem.wage_type_code !== 'NST' && '-'}
                {editCodeValue && 
                <input 
                    type="number"
                    className="text-input__payroll"
                    name="newRegWageAmt"
                    value={newRegWageAmt || ''}
                    disabled={newWageCode !== 'NST'} 
                    onChange={e => setNewRegWageAmt(e.target.value) } 
                />}
            </td>
            <td>
                {!editCodeValue &&
                    employeeItem.rent_deduction_desc
                }
                {editCodeValue && 
                    <select className="select__table" value={newRentCode} onChange={e => setNewRentCode(e.target.value)}>
                        {deductCodeData.map((deductCodeItem) => (
                            <option key={deductCodeItem.deduction_type_code} value={deductCodeItem.deduction_type_code}>{deductCodeItem.deduction_type_desc}</option>
                        ))}
                    </select>
                }
            </td>
            <td>
                {!editCodeValue && employeeItem.rent_deduction_code === 'NST' && employeeItem.rent_deduction_amt}
                {!editCodeValue && employeeItem.rent_deduction_code !== 'NST' && '-'}
                {editCodeValue && 
                <input 
                    type="number"
                    className="text-input__payroll"
                    name="newRentAmt"
                    value={newRentAmt || ''}
                    disabled={newRentCode !== 'NST'} 
                    onChange={e => setNewRentAmt(e.target.value) } 
                />}
            </td>
            <td>
                {!editCodeValue &&
                    employeeItem.transportation_deduction_desc
                }
                {editCodeValue && 
                    <select className="select__table" value={newTransportCode} onChange={e => setNewTransportCode(e.target.value)}>
                        {deductCodeData.map((deductCodeItem) => (
                            <option key={deductCodeItem.deduction_type_code} value={deductCodeItem.deduction_type_code}>{deductCodeItem.deduction_type_desc}</option>
                        ))}
                    </select>
                }
            </td>
            <td>
                {!editCodeValue && employeeItem.transportation_deduction_code === 'NST' && employeeItem.transportation_deduction_amt}
                {!editCodeValue && employeeItem.transportation_deduction_code !== 'NST' && '-'}
                {editCodeValue && 
                <input 
                    type="number"
                    className="text-input__payroll"
                    name="newTransportAmt"
                    value={newTransportAmt || ''}
                    disabled={newTransportCode !== 'NST'} 
                    onChange={e => setNewTransportAmt(e.target.value) } 
                />}
            </td>
            <td>
                <button className="table-button" onClick={onEditClick} >{buttonLabel}</button>
            </td>
            <td>
                <button className="table-button" onClick={saveCodeValue} 
                    disabled={saving || !editCodeValue ||
                        (newWageCode === 'NST' && !newRegWageAmt ) ||
                        (newRentCode === 'NST' && !newRentAmt) ||
                        (newTransportCode === 'NST' && !newTransportAmt)
                    } >Save
                </button>
            </td>
        </tr>
    )
}

export default EmployeeItem