import DailyMeat from './payroll-reports/DailyMeat'
import DailyButcher from './payroll-reports/DailyButcher'
import DailyVacuum from './payroll-reports/DailyVacuum'
import DailyGradedTails from './payroll-reports/DailyGradedTails'
import DailyGlobal from './payroll-reports/DailyGlobal'
import WeeklySummary from './payroll-reports/WeeklySummary'
import WeeklyPayroll from './payroll-reports/WeeklyPayroll'

const PayrollReportOptions = [
    {
        title: 'Daily Meat Line Bonus Report',
        path: '/payroll-reports/daily-meat',
        component: DailyMeat,
        authorized: 'user-group'
    },
    {
        title: 'Daily Butcher Bonus Report',
        path: '/payroll-reports/daily-butcher',
        component: DailyButcher,
        authorized: 'user-group'
    },
    {
        title: 'Daily Vacuum Bonus Report',
        path: '/payroll-reports/daily-vacuum',
        component: DailyVacuum,
        authorized: 'user-group'
    },
    {
        title: 'Daily Graded Tails Report',
        path: '/payroll-reports/daily-graded-tails',
        component: DailyGradedTails,
        authorized: 'graded-tails-group'
    },
    {
        title: 'Daily Global Bonus Report',
        path: '/payroll-reports/daily-global',
        component: DailyGlobal,
        authorized: 'user-group'
    },
    {
        title: 'Weekly Bonus Summary Report',
        path: '/payroll-reports/weekly-summary',
        component: WeeklySummary,
        authorized: 'user-group'
    },
    {
        title: 'Weekly Payroll Report',
        path: '/payroll-reports/weekly-payroll',
        component: WeeklyPayroll,
        authorized: 'payroll-group'
    }
]

export default PayrollReportOptions