import { useState, useRef, useEffect } from 'react'
import {Auth} from 'aws-amplify'
import axios from 'axios'
import moment from 'moment'
import {useHistory } from 'react-router-dom'

const EmployeeItemAdd = ({wageCodeData, deductCodeData, recentEmployeeData, setEmployeeData, isAddMode, setErrorMsg}) => {
    const [newEmployeeId, setNewEmployeeId] = useState()
    const [newStartDate, setNewStartDate] = useState()
    const [newEndDate, setNewEndDate] = useState()
    const [newPayrollId, setNewPayrollId] = useState()
    const [newFirstName, setNewFirstName] = useState('')
    const [newLastName, setNewLastName] = useState('')
    const [newWageCode, setNewWageCode] = useState('')
    const [newRegWageAmt, setNewRegWageAmt] = useState()
    const [newRentCode, setNewRentCode] = useState()
    const [newRentAmt, setNewRentAmt] = useState()
    const [newTransportCode, setNewTransportCode] = useState()
    const [newTransportAmt, setNewTransportAmt] = useState()
    const [saving, setSaving] = useState(false)
    const prevStartDate = useRef()

    let history = useHistory()

    const onStartDateFieldChange = (e) => {
        setNewStartDate(e.target.value)
        prevStartDate.current = newStartDate
    }

    useEffect(() => {
        if (newStartDate) {
            setNewEndDate(moment(newStartDate).subtract(1, "days").format('YYYY-MM-DD'))
            if (newStartDate && recentEmployeeData) {
                setNewEmployeeId(recentEmployeeData.employee_id)
                setNewFirstName(recentEmployeeData.first_name)
                setNewLastName(recentEmployeeData.last_name)
                setNewPayrollId(recentEmployeeData.payroll_employee_id)
            }
            if (newStartDate) {
                if (isAddMode) {
                    if (!newPayrollId) setNewPayrollId(null)
                }
                if (!newWageCode) {
                    setNewWageCode('TFW')
                }
                if (!newRentCode) {
                    setNewRentCode('NON')
                }
                if(!newTransportCode) {
                    setNewTransportCode('NON')
                }
            }
        } else {
            setNewEndDate(null)
            setNewFirstName(null)
            setNewLastName(null)
            setNewPayrollId(null)
            setNewWageCode(null)
            setNewRentCode(null)
            setNewTransportCode(null)
        }
    }, [newStartDate])

    useEffect(() => {
        if (newWageCode !== 'NST') {
            setNewRegWageAmt(null)
        }
    }, [newWageCode])

    useEffect(() => {
        if (newRentCode !== 'NST') {
            setNewRentAmt(null)
        }
    }, [newRentCode])

    useEffect(() => {
        if (newTransportCode !== 'NST') {
            setNewTransportAmt(null)
        }
    }, [newTransportCode])

    const saveCodeValue = (e) => {
        e.preventDefault()

        setSaving(true)
        setErrorMsg(null)

        let updateType = 'add'
        if (!isAddMode) {
            updateType = 'insert'
        }

        axios
            .put(`${process.env.REACT_APP_API_URL}/employeedetail`, {employeeId: newEmployeeId, startDate: newStartDate, endDate: newEndDate,
                payrollId: newPayrollId, firstName: newFirstName, lastName: newLastName, wageCode: newWageCode, regWageAmt: newRegWageAmt,
                rentCode: newRentCode, rentAmt: newRentAmt, transportCode: newTransportCode, transportAmt: newTransportAmt, updateType} )
            .then(response => {
                setSaving(false)
                if (response.data.error) {
                    setErrorMsg(response.data.error)
                } 
                if (isAddMode) {
                    history.push(`/admin-panel/EmployeeEdit/${newEmployeeId}`)
                } else {
                    setEmployeeData(response.data.employeeRows)
                    setNewStartDate(null)
                }
            }) 
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem saving data: ', error)
                setSaving(false)
            })                 
    }

    return (
        <tr>
            {isAddMode && 
            <td>
                <input 
                type="number"
                className="text-input__payroll"
                name="newEmployeeId"
                value={newEmployeeId || ''}
                onChange={e => setNewEmployeeId(e.target.value) } />
            </td>
            }
            <td>
                <input 
                type="date"
                className="text-input__date"
                name="newStartDate"
                value={newStartDate || ''}
                onChange={e => onStartDateFieldChange(e) } />
            </td>
            <td>
                {'-'}
            </td>
            <td>
                <input 
                type="number"
                className="text-input__payroll"
                name="newPayrollId"
                value={newPayrollId || ''}
                disabled={!newStartDate}
                onChange={e => setNewPayrollId(e.target.value) } />
            </td>
            <td>
                <input 
                type="text"
                className="text-input__name"
                name="newFirstName"
                value={newFirstName || ''}
                disabled={!newStartDate}
                onChange={e => setNewFirstName(e.target.value) } />
            </td>
            <td>
                <input 
                type="text"
                className="text-input__name"
                name="newLastName"
                value={newLastName || ''}
                disabled={!newStartDate}
                onChange={e => setNewLastName(e.target.value) } />
            </td>
            <td>
                <select disabled={!newStartDate} className="select__table" value={newWageCode || ''} onChange={e => setNewWageCode(e.target.value)}>
                    {!newStartDate && <option key="empty" selected={!newStartDate}></option>}
                    {wageCodeData.map((wageCodeItem) => (
                        <option key={wageCodeItem.wage_type_code} value={wageCodeItem.wage_type_code}>{wageCodeItem.wage_type_desc}</option>
                    ))}
                </select>
            </td>
            <td>
                <input 
                type="number"
                className="text-input__payroll"
                name="newRegWageAmt"
                value={newRegWageAmt || ''}
                disabled={!newStartDate || newWageCode !== 'NST'} 
                onChange={e => setNewRegWageAmt(e.target.value) } />
            </td>
            <td>
                <select disabled={!newStartDate} className="select__table" value={newRentCode || ''} onChange={e => setNewRentCode(e.target.value)}>
                    {!newStartDate && <option key="empty" selected={!newStartDate}></option>}
                    {deductCodeData.map((deductCodeItem) => (
                        <option key={deductCodeItem.deduction_type_code} value={deductCodeItem.deduction_type_code}>{deductCodeItem.deduction_type_desc}</option>
                    ))}
                </select>
            </td>
            <td>
                <input 
                type="number"
                className="text-input__scale"
                name="newRentAmt"
                value={newRentAmt || ''}
                disabled={!newStartDate || newRentCode !== 'NST'} 
                onChange={e => setNewRentAmt(e.target.value) } />
            </td>
            <td>
                <select disabled={!newStartDate} className="select__table" value={newTransportCode || ''} onChange={e => setNewTransportCode(e.target.value)}>
                    {!newStartDate && <option key="empty" selected={!newStartDate}></option>}
                    {deductCodeData.map((deductCodeItem) => (
                        <option key={deductCodeItem.deduction_type_code} value={deductCodeItem.deduction_type_code}>{deductCodeItem.deduction_type_desc}</option>
                    ))}
                </select>
            </td>
            <td>
                <input 
                type="number"
                className="text-input__scale"
                name="newTransportAmt"
                value={newTransportAmt || ''}
                disabled={!newStartDate || newTransportCode !== 'NST'} 
                onChange={e => setNewTransportAmt(e.target.value) } />
            </td>
            <td>
                <button className="table-button" onClick={saveCodeValue} 
                    disabled={saving || !newStartDate || !newFirstName || !newLastName ||
                        (newWageCode === 'NST' && !newRegWageAmt ) ||
                        (newRentCode === 'NST' && !newRentAmt) ||
                        (newTransportCode === 'NST' && !newTransportAmt) ||
                        (isAddMode && !newEmployeeId)
                    } >Add
                </button>
            </td>
        </tr>
    )
}

export default EmployeeItemAdd