import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

const ReferenceItemAdd = ({getReferenceData, setCodeTypeData}) => {
    const [newCodeValue, setNewCodeValue] = useState()
    const [newStartDate, setNewStartDate] = useState()
    const [newEndDate, setNewEndDate] = useState()
    const [saving, setSaving] = useState(false)
    const prevCodeValue = useRef()
    const prevStartDate = useRef()

    const onStartDateFieldChange = (e) => {
        setNewStartDate(e.target.value)
        prevStartDate.current = newStartDate
    }

    useEffect(() => {
        setNewEndDate(moment(newStartDate).subtract(1, "days").format('YYYY-MM-DD'))
    }, [newStartDate])

    const onCodeValueFieldChange = (e) => {
        setNewCodeValue(e.target.value)
        prevCodeValue.current = newCodeValue
    }

    const saveCodeValue = (e) => {
        e.preventDefault()

        setSaving(true)
        const codeType = document.getElementById("codeTypeSelected").value
        console.log(codeType)
        // const codeType = 'MBS'
        axios
            .put(`${process.env.REACT_APP_API_URL}/referencedata`, {codeType: codeType, startDate: newStartDate, codeValue: newCodeValue, newEndDate: newEndDate, updateType: "insert"} )
            .then(response => {
                setSaving(false)
                setCodeTypeData(response.data.rows)
                setNewCodeValue(null)
                setNewStartDate(null)
            }) 
            .catch(error => {
                console.log('The error is: ', error)
                console.log('Data: ', newStartDate, newCodeValue, newEndDate)
                setSaving(false)
            })                 
    }

    return (
        <tr>
            <td>
                <input 
                type="date"
                className="text-input__date"
                name="newStartDate"
                value={newStartDate || ''}
                onChange={e => onStartDateFieldChange(e) } />
            </td>
            <td>
                {'-'}
            </td>
            <td>
                <input 
                type="number"
                min="0"
                className="text-input__scale"
                name="newCodeValue"
                value={newCodeValue || ''}
                onChange={e => onCodeValueFieldChange(e) } />
            </td>
            <td></td>
            <td>
                <button className="table-button" onClick={saveCodeValue} disabled={saving || !newStartDate || !newCodeValue} >Add</button>
            </td>
        </tr>
    )
}

export default ReferenceItemAdd