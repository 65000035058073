import RawMaterial from './prod-data/RawMaterial'
import ScaleData from './prod-data/ScaleData'
import ScaleAverage from './prod-data/ScaleAverage'
import ButcherData from './prod-data/ButcherData'
import VacuumData from './prod-data/VacuumData'
import GradedTailData from './prod-data/GradedTailsData'

const ProductionDataOptions = [
    {
        title: 'Raw Material',
        path: '/prod-data/raw-material',
        component: RawMaterial,
        authorized: 'user-group'
    },
    {
        title: 'Scale Data',
        path: '/prod-data/scale-data',
        component: ScaleData,
        authorized: 'user-group'
    },
    {
        title: 'Scale Average Bonus',
        path: '/prod-data/scale-average',
        component: ScaleAverage,
        authorized: 'user-group'
    },
    {
        title: 'Butcher Data',
        path: '/prod-data/butcher-data',
        component: ButcherData,
        authorized: 'user-group'
    },
    {
        title: 'Vacuum Data',
        path: '/prod-data/vacuum-data',
        component: VacuumData,
        authorized: 'user-group'
    },
    {
        title: 'Graded Tails',
        path: '/prod-data/graded-tails',
        component: GradedTailData,
        authorized: 'graded-tails-group'
    }    
]

export default ProductionDataOptions