import moment from 'moment'
import React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

export default class WeeklyReportDate extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            calendarFocused: false,
            calendarLabel: 'Report Date'
        }
    }

    onDateChange = (reportDate) => {
        if (reportDate) {
            this.props.updateReportDate(reportDate)
        }
    }
    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calendarFocused: focused}))
    }
    isOutsideRange = (day) => {
        return day.isAfter(moment())
    }

    isDayBlocked = (day) => {
        if (day.day() !== 6)
            return true
    }

    render() {
        return (
            <div className="reportdate">
                <div className="reportdate__title">
                    <h4>For Week Ending:</h4>
                </div>
                    <SingleDatePicker
                                date={this.props.reportDate}
                                onDateChange={this.onDateChange}
                                focused={this.state.calendarFocused}
                                onFocusChange={this.onFocusChange}
                                numberOfMonths={1}
                                isOutsideRange={this.isOutsideRange}
                                isDayBlocked={this.isDayBlocked}
                                showDefaultInputIcon inputIconPosition="after"
                                small
                    />
            </div>
        )
    }
}