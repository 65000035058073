import { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import ReportDate from '../ReportDate'
import DateRange from '../DateRange'
import MaterialItem from './MaterialItem'

const RawMaterial = () => {
    const [productionDate, setProductionDate] = useState(moment().subtract(1, "days"))
    const [startDate, setStartDate] = useState(moment().subtract(1, "months"))
    const [endDate, setEndDate] = useState(moment())
    const [materialData, setMaterialData] = useState([])
    const [rawMaterial, setRawMaterial] = useState(0)
    const [errorMsg, setErrorMsg] = useState('')
    const [retrieveErrorMsg, setRetrieveErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const updateMaterialData = (index, materialItem) => {
        let newMaterialData = materialData
        newMaterialData[index] = materialItem
        setMaterialData(newMaterialData)
    }

    const getProductionData = (e) => {
        e.preventDefault()

        setRetrieveErrorMsg('')
        setLoading(true)
        setMaterialData([])
        axios
        .get(`${process.env.REACT_APP_API_URL}/rawmaterial?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`)
        .then(response => {
            setMaterialData(response.data.rows)
            setLoading(false)
            if(response.data.rows.length === 0) {
                setRetrieveErrorMsg('No Data Found')
            }    
        })
        .catch(error => {
            console.log('The error is: ', error)
            setRetrieveErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!rawMaterial) {
            setErrorMsg('Please provide Raw Material value')
        } else {
            setErrorMsg('')
            setSaving(true)
            axios
                .put(`${process.env.REACT_APP_API_URL}/rawmaterial`, {productionDate: productionDate.format('YYYY-MM-DD'), poundsProcessed: rawMaterial, updateType: "insert"} )
                .then(response => {
                    setSaving(false)
                }) 
                .catch(error => {
                    let errorResp = ''
                    if (error.response) {
                        errorResp = error.response.data
                    }
                    console.log('The error is: ', errorResp)
                    setErrorMsg(`Problem saving data: ${errorResp}`)
                    setSaving(false)
                })                 
        }
    }

    return (
        <div className="report">
            <h3>Daily Raw Material Production</h3>
            <form onSubmit={onSubmit}>
                <div className="reportdate">
                    <ReportDate reportDate={productionDate} updateReportDate={setProductionDate}/>
                    <div className="formdata">
                        <label className="formdata__label">Raw Material Processed: </label>
                        <input 
                            type="number" 
                            className={ errorMsg ? "text-input__error" : "text-input" } 
                            name="rawMaterial" 
                            value={rawMaterial}
                            onChange={e => setRawMaterial(e.target.value)}/>
                    </div>
                    <div className="reportdate__button">
                        <button className="button" disabled={saving}>Save Raw Material</button>
                    </div>
                </div>
            </form>
            { saving && <h3>Saving...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <h3>Raw Material History</h3>
            <div className="reportdate">
                <DateRange startDate={startDate} updateStartDate={setStartDate} endDate={endDate} updateEndDate={setEndDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getProductionData} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {retrieveErrorMsg && <p className="form__error">{retrieveErrorMsg}</p>}
            {materialData.length > 0 && <div >
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Raw Material</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {materialData.map((materialItem, index) => (
                            <MaterialItem key={index} materialItemNo={index} materialItem={materialItem} setMaterialData={updateMaterialData} />
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default RawMaterial