import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

const MaterialItem = ({materialItemNo, materialItem, setMaterialData}) => {
    const [overrideLbs, setOverrideLbs] = useState(materialItem.pounds_processed)
    const [editPounds, setEditPounds] = useState(false)
    const [buttonLabel, setButtonLabel] = useState('Edit')
    const [saving, setSaving] = useState(false)
    const prevOverrideLbs = useRef()

    useEffect(() => {
        setButtonLabel(editPounds ? 'Cancel' : 'Edit')
    }, [editPounds])

    const onOverrideFieldChange = (e) => {
        setOverrideLbs(e.target.value)
        prevOverrideLbs.current = overrideLbs
    }

    const onEditClick = () => {
        setEditPounds(!editPounds)
        if (!editPounds) {
            setOverrideLbs(materialItem.pounds_processed)
        }
    }

    const savePounds = (e) => {
        e.preventDefault()

        setSaving(true)
        axios
            .put(`${process.env.REACT_APP_API_URL}/rawmaterial`, {productionDate: materialItem.production_date, poundsProcessed: overrideLbs, updateType: "update"} )
            .then(response => {
                setSaving(false)
                const newMaterialItem = materialItem
                newMaterialItem.pounds_processed = overrideLbs
                setMaterialData(materialItemNo, newMaterialItem)
                setEditPounds(false)
            }) 
            .catch(error => {
                console.log('The error is: ', error)
                setSaving(false)
            })                 
    }

    return (
        <tr>
            <td>
                {materialItem.production_date}
            </td>
            <td>
                {!editPounds &&
                    materialItem.pounds_processed
                }
                {editPounds &&
                    <input 
                    type="number"
                    min="0"
                    className="text-input__scale"
                    name="editPounds"
                    value={overrideLbs}
                    onChange={e => onOverrideFieldChange(e) } />

                }
            </td>
            <td>
                <button className="table-button" onClick={onEditClick} >{buttonLabel}</button>
            </td>
            <td>
                <button className="table-button" onClick={savePounds} disabled={saving || !editPounds || (overrideLbs === materialItem.pounds_processed)} >Save</button>
            </td>
        </tr>
    )
}

export default MaterialItem