import { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import ReportDate from '../ReportDate'
import ScaleItemAverage from "./ScaleItemAverage"

const ScaleAverage = () => {
    const [productionDate, setProductionDate] = useState(moment().subtract(1, "days"))
    const [scaleData, setScaleData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const updateScaleData = (index, scaleItem) => {
        let newScaleData = scaleData
        newScaleData[index] = scaleItem
        setScaleData(newScaleData)
    }

    const getProductionData = (e) => {
        e.preventDefault()

        setErrorMsg('')
        setLoading(true)
        setScaleData([])
        axios
        .get(`${process.env.REACT_APP_API_URL}/scaleaverage?productionDate=${productionDate.format('YYYY-MM-DD')}`)
        .then(response => {
            setScaleData(response.data.rows)
            setLoading(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }
    const onSubmit = (e) => {
        e.preventDefault()

        // console.log(scaleData)    
        const averageRows = scaleData.filter(scaleItem => scaleItem.override_hrs)
            .map(({employee_id, override_hrs}) => ({employee_id, override_hrs}))
        console.log(averageRows)

        setErrorMsg('')
        setSaving(true)
        axios
        .put(`${process.env.REACT_APP_API_URL}/scaleaverage`, {productionDate: productionDate.format('YYYY-MM-DD'), averageRows})
        .then(response => {
            setSaving(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setSaving(false)
        })  
  
    }

    return (
        <div className="report">
            <h3>Daily Meat Line - Assign Average Bonus</h3>
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="reportdate">
                <ReportDate reportDate={productionDate} updateReportDate={setProductionDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getProductionData} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {scaleData.length > 0 && 
            <form onSubmit={onSubmit}>
                <div className="table-layout">
                    <div className="reportdate__button formdata__padding">
                        <button className="button" disabled={saving}>Save Data</button>
                    </div>
                    { saving && <h3>Saving Data...</h3>}
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Employee Id</th>
                                <th>CT Hours</th>
                                <th>Meat Line Hours</th>
                                <th>Butcher Hours</th>
                                <th>Vacuum Hours</th>
                                <th>Available Hours</th>
                                <th>Avg?</th>
                                <th>Average Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scaleData.map((scaleItem, index) => (
                                <ScaleItemAverage key={index} scaleItemNo={index} scaleItem={scaleItem} setScaleData={updateScaleData} />
                            ))}
                        </tbody>
                    </table>
                    <div className="reportdate__button formdata">
                        <button className="button" disabled={saving}>Save Data</button>
                    </div>
                    { saving && <h3>Saving Data...</h3>}
                </div>
            </form>
            }
        </div>
    )        
}

export default ScaleAverage