import { useState, useEffect } from 'react'
import {Auth} from 'aws-amplify'
import moment from 'moment'
import axios from 'axios'
import EmployeeItemAdd from './EmployeeItemAdd'
import EmployeeItem from './EmployeeItem'
import { useParams } from 'react-router-dom'

const EmployeeEdit = () => {
    const [wageCodeData, setWageCodeData] = useState([])
    const [deductCodeData, setDeductCodeData] = useState([])
    const [employeeData, setEmployeeData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const { id } = useParams()
    const isAddMode = !id

    useEffect(() => {
        getEmployeeDetail()
    }, [])

    const getEmployeeDetail = async () => {
        setErrorMsg('')
        setLoading(true)
        setEmployeeData([])
        setWageCodeData([])
        setDeductCodeData([])
        let session = await Auth.currentSession()
        let jwt = session.getIdToken().getJwtToken()
        // console.log('JWT: ', jwt)
        // console.log('ID TOKEN: ', session.getIdToken())
        // console.log('Access TOKEN: ', session.getAccessToken().getJwtToken())
        // console.log('SESSION', session)
        axios
            .get(`${process.env.REACT_APP_API_URL}/employeedetail?id=${id}`, 
            {headers: {Authorization: jwt}}
            )
        .then(response => {
            setEmployeeData(response.data.employeeRows)
            setWageCodeData(response.data.wageCodeRows)
            setDeductCodeData(response.data.deductionCodeRows)
            setLoading(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    return (
        <div className="report">
            {isAddMode && <h3>Add New Employee</h3>}
            {!isAddMode && <h3>Employee Detail for Employee Id {id}</h3>}
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div>
                {(employeeData && (employeeData.length > 0 || isAddMode)) && 
                <table className="styled-table styled-table__fixed narrow-table">
                    <thead>
                        <tr>
                            {isAddMode && <th>Employee Id</th>}
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Payroll Id</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Wage Type</th>
                            <th>Non-Standard Reg Wage Amt</th>
                            <th>Rent Deduction</th>
                            <th>Non-Standard Rent Deduction Amt</th>
                            <th>Transportation Deduction</th>
                            <th>Non-Standard Transportation Deduction Amt</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <EmployeeItemAdd 
                            wageCodeData={wageCodeData} 
                            deductCodeData={deductCodeData} 
                            recentEmployeeData={employeeData[0]} 
                            setEmployeeData={setEmployeeData}
                            isAddMode={isAddMode}
                            setErrorMsg={setErrorMsg}
                        />
                        {employeeData.map((employeeItem) => (
                            <EmployeeItem 
                                key={employeeItem.start_date}
                                employeeItem={employeeItem}
                                wageCodeData={wageCodeData} 
                                deductCodeData={deductCodeData} 
                                setEmployeeData={setEmployeeData}
                             />
                    ))}
                    </tbody>
                </table>}
            </div>
        </div>
    )
}

export default EmployeeEdit