import { useState, useEffect, useRef } from 'react'
import axios from 'axios'

const ReferenceItem = ({referenceItemNo, referenceItem, setReferenceData}) => {
    const [newCodeValue, setNewCodeValue] = useState(referenceItem.code_value)
    const [editCodeValue, setEditCodeValue] = useState(false)
    const [buttonLabel, setButtonLabel] = useState('Edit')
    const [saving, setSaving] = useState(false)
    const prevCodeValue = useRef()

    useEffect(() => {
        setButtonLabel(editCodeValue ? 'Cancel' : 'Edit')
    }, [editCodeValue])

    const onOverrideFieldChange = (e) => {
        setNewCodeValue(e.target.value)
        prevCodeValue.current = newCodeValue
    }

    const onEditClick = () => {
        setEditCodeValue(!editCodeValue)
        if (!editCodeValue) {
            setEditCodeValue(referenceItem.code_value)
        }
    }

    const saveCodeValue = (e) => {
        e.preventDefault()

        setSaving(true)
        axios
            .put(`${process.env.REACT_APP_API_URL}/referencedata`, {id: referenceItem.id, codeValue: newCodeValue, updateType: "update"} )
            .then(response => {
                setSaving(false)
                const newReferenceItem = referenceItem
                newReferenceItem.code_value = newCodeValue
                setReferenceData(referenceItemNo, newReferenceItem)
                setEditCodeValue(false)
            }) 
            .catch(error => {
                console.log('The error is: ', error)
                setSaving(false)
            })                 
    }

    return (
        <tr>
            <td>
                {referenceItem.start_date}
            </td>
            <td>
                {referenceItem.end_date || '-'}
            </td>
            <td>
                {!editCodeValue &&
                    referenceItem.code_value
                }
                {editCodeValue &&
                    <input 
                    type="number"
                    min="0"
                    className="text-input__scale"
                    name="editCodeValue"
                    value={newCodeValue}
                    onChange={e => onOverrideFieldChange(e) } />

                }
            </td>
            <td>
                <button className="table-button" onClick={onEditClick} >{buttonLabel}</button>
            </td>
            <td>
                <button className="table-button" onClick={saveCodeValue} disabled={saving || !editCodeValue || (newCodeValue === referenceItem.code_value)} >Save</button>
            </td>
        </tr>
    )
}

export default ReferenceItem