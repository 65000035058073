import { useState, useEffect, useRef } from 'react'

const GradedTailItem = ({gradedTailItemNo, gradedTailItem, setGradedTailData}) => {
    const [ovrGradedTailId, setOvrGradedTailId] = useState(gradedTailItem.override_graded_tail_id)
    const [overrideInd, setOverrideInd] = useState(gradedTailItem.override_graded_tail_id ? true : false)
    const prevGradedTailId = useRef()
    const prevOverrideInd = useRef()

    const onGradedTailIdFieldChange = (e) => {
        setOvrGradedTailId(e.target.value)
        prevGradedTailId.current = ovrGradedTailId
    }

    const onOverrideIndFieldChange = (e) => {
        setOverrideInd(e.target.checked)
        prevOverrideInd.current = overrideInd
        if (!prevOverrideInd.current) {
            setOvrGradedTailId(gradedTailItem.graded_tail_id)
            prevGradedTailId.current = ovrGradedTailId
        } else {
          setOvrGradedTailId(null)
          prevGradedTailId.current = ovrGradedTailId
        }
    }

    useEffect(() => {
        if (ovrGradedTailId || prevGradedTailId.current) {
            const newGradedTailItem = gradedTailItem
            newGradedTailItem.override_graded_tail_id = ovrGradedTailId
            setGradedTailData(gradedTailItemNo, newGradedTailItem)
        }
    }, [ovrGradedTailId, gradedTailItem, gradedTailItemNo, setGradedTailData])

    return (
        <tr>
            <td>
                {gradedTailItem.end_time}
            </td>
            <td>
                {gradedTailItem.weight}
            </td>
            <td>
                {gradedTailItem.graded_tail_id}
            </td> 
            {gradedTailItem.graded_tail_desc && 
              <td>
              {gradedTailItem.graded_tail_desc}
            </td>             
            }
            {!gradedTailItem.graded_tail_desc &&
              <td bgcolor="red">
              -
            </td>               
            }           
            <td>           
              <input 
                    type="checkbox"
                    value={overrideInd || ''}
                    checked={overrideInd}
                    onChange={e => onOverrideIndFieldChange(e) } />
            </td>
            <td>
                <input 
                    type="number"
                    min="0"
                    disabled={!overrideInd}
                    className="text-input__scale"
                    name="ovrGradedTailId"
                    value={ovrGradedTailId || ''}
                    onChange={e => onGradedTailIdFieldChange(e) } />
            </td>
        </tr>
    )
}

export default GradedTailItem