import { useState } from 'react'
import axios from 'axios'
import ReferenceItem from './ReferenceItem'
import ReferenceItemAdd from './ReferenceItemAdd'

const ReferenceUpdate = ({referenceData}) => {
    const [codeTypeData, setCodeTypeData] = useState([])
    const [retrieveErrorMsg, setRetrieveErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    
    const updateCodeTypeData = (index, codeTypeItem) => {
        let newCodeTypeData = codeTypeData
        newCodeTypeData[index] = codeTypeItem
        setCodeTypeData(newCodeTypeData)
    }

    const getReferenceData = (e) => {
        e.preventDefault()

        const codeType = document.getElementById("codeTypeSelected").value

        setRetrieveErrorMsg('')
        setLoading(true)
        setCodeTypeData([])
        axios
        .get(`${process.env.REACT_APP_API_URL}/referencedata?codeType=${codeType}`)
        .then(response => {
            console.log(response.data.rows)
            setCodeTypeData(response.data.rows)
            setLoading(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setRetrieveErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    return (
        <div>
            <h3>Add / Update Reference Item</h3>
            <div className="reportdate">
                <label >Reference Item:  </label>
                <select className="select formdata__label" id="codeTypeSelected">
                    {referenceData.map((referenceItem) => (
                        <option key={referenceItem.code_type} value={referenceItem.code_type}>{referenceItem.code_short_desc}</option>
                    ))}
                </select>
                <div className="reportdate__button">
                    <button className="button" onClick={getReferenceData} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {retrieveErrorMsg && <p className="form__error">{retrieveErrorMsg}</p>}
            {codeTypeData.length > 0 && <div >
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Value</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ReferenceItemAdd getReferenceData={getReferenceData} setCodeTypeData={setCodeTypeData}/>
                        {codeTypeData.map((codeTypeItem, index) => (
                            <ReferenceItem key={index} referenceItemNo={index} referenceItem={codeTypeItem} setReferenceData={updateCodeTypeData} />
                        ))}
                    </tbody>
                </table>
            </div>}
        </div> 
    )
}
export default ReferenceUpdate