import { useState } from 'react'
import axios from 'axios'
import DefaultStationList from './DefaultStationList'

const ReferenceData = () => {
    const [defaultStationData, setDefaultStationData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const updateDefaultStationData = (index, defaultStationItem) => {
        let newDefaultStationData = defaultStationData
        newDefaultStationData[index] = defaultStationItem
        setDefaultStationData(newDefaultStationData)
    }

    const getDefaultStations = () => {

        const counterType = document.getElementById("counterTypeSelected").value

        setErrorMsg('')
        setLoading(true)
        setDefaultStationData([])
        axios
        .get(`${process.env.REACT_APP_API_URL}/defaultstation?counterType=${counterType}`)
        .then(response => {
            setDefaultStationData(response.data.rows)
            setLoading(false)
            })
        .catch(error => {
            console.log('The error is: ', error)
            setErrorMsg('Problem retrieving data: ', error)
            setLoading(false)
        })            
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const counterType = document.getElementById("counterTypeSelected").value
        console.log(counterType)

        setErrorMsg('')
        setSaving(true)
        console.log(defaultStationData)
        axios
            .put(`${process.env.REACT_APP_API_URL}/defaultstation`, {counterType, defaultRows: defaultStationData} )
            .then(response => {
                setDefaultStationData(response.data.rows.map(newStationItem => {
                    return newStationItem
                }))
                setSaving(false)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem saving data: ', error)
                setSaving(false)
            })            
    }

    return (
        <div className="report">
            <h3>Employee Default Station</h3>
            <div className="reportdate">
                <label >Counter Machine:  </label>
                <select className="select formdata__label" id="counterTypeSelected">
                        <option key="butcher" value="butcher">Butcher Counter Machine</option>
                        <option key="vacuum" value="vacuum">Vacuum Counter Machine</option>
                </select>
                <div className="reportdate__button">
                    <button className="button" onClick={getDefaultStations} disabled={loading}>Retrieve Data</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            {defaultStationData.length > 0 && <div >
                <form onSubmit={onSubmit}>
                    <div className="table-layout">
                        <div className="reportdate__button formdata__padding">
                            <button className="button" disabled={loading}>Save Default Data</button>
                        </div>
                        { saving && <h3>Saving Data...</h3>}
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Station Id</th>
                                    <th>Employee Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {defaultStationData.map((defaultStationItem, index) => (
                                    <DefaultStationList key={index} defaultItemNo={index} defaultStationItem={defaultStationItem} setDefaultStationData={updateDefaultStationData} />
                                ))}
                            </tbody>
                        </table>
                        <div className="reportdate__button formdata">
                            <button className="button" disabled={loading}>Save Default Data</button>
                        </div>
                        { saving && <h3>Saving Data...</h3>}
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default ReferenceData