import {Amplify} from 'aws-amplify'
import { withAuthenticator, useTheme, View, Image, Heading, Flex, Grid } from '@aws-amplify/ui-react';
import AppRouter from './routers/AppRouter'
import '@aws-amplify/ui-react/styles.css'
import './styles/styles.scss'
import awsExports from './aws-exports'
import logo from './assets/images/pointe-sapin-seafoods-logo.png'

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <Grid templateColumns={{ base: " 0", medium: " 1fr" }}>
      <Flex backgroundColor={tokens.colors.background.secondary}
      justifyContent="center">

      </Flex>
      <View textAlign="center" >
        <Image
          alt="Pointe-Sapin Seafoods logo"
          src={logo}
          width="100%"
          height="100%"
          objectFit="cover"
          backgroundColor="white"
        />
      </View>
      </Grid>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}>
          Sign in to your account
        </Heading>
      );
    },
  },
}

function App() {
  return (
    <AppRouter />
  );
}

export default withAuthenticator(App, { includeGreetings: true, hideSignUp: true, components });