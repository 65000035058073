import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from '../components/Header'
import PayrollReports from '../components/PayrollReports'
import ProductionData from '../components/ProductionData'
import AdministrativePanel from '../components/AdministrativePanel'
import WelcomePanel from '../components/WelcomePanel'

const AppRouter = () => (
    <BrowserRouter>
        <div>
            <Header />
            <Switch>
                <Route path="/" component={WelcomePanel} exact/>
                <Route path="/payroll-reports" component={PayrollReports}/>
                <Route path="/prod-data" component={ProductionData}/>
                <Route path="/admin-panel" component={AdministrativePanel} />
            </Switch>
        </div>
    </BrowserRouter>
)

export default AppRouter