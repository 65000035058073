import { useState, useMemo } from 'react'
import moment from 'moment'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {utils, writeFile} from 'xlsx'
import WeeklyReportDate from '../WeeklyReportDate'
import ReportTable from './ReportTable'
import { createNewSheet } from '../Utils'

const WeeklySummary = () => {
    const [reportDate, setReportDate] = useState(moment().day(-1))
    const [reportData, setReportData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const getReportDate = () => {
        setLoading(true)
        setErrorMsg(null)
        setReportData([])
        axios
            .get(`${process.env.REACT_APP_API_URL}/weeklysummary?reportDate=${reportDate.format('YYYY-MM-DD')}`)
            .then(response => {
                setReportData(response.data.rows)
                if(response.data.rows.length === 0) {
                    setErrorMsg('No Data Found')
                }
                setLoading(false)
            })
            .catch(error => {
                console.log('The error is: ', error)
                setErrorMsg('Problem retrieving data: ', error)
                setLoading(false)
            })  
    }

    const downloadPDF = () => {
        const doc = new jsPDF()
        doc.autoTable({ styles: {halign: 'center'}, html: '#reportData' })
        doc.save(`WeeklySummaryReport${reportDate.format('YYYY-MM-DD')}.pdf`)
    }

    const downloadXLSX = () => {
        const wb = utils.book_new();

        // get the data from the table - as this will be the sorted version
        const table = document.getElementById("reportData")
        const ws = createNewSheet(table)
        utils.book_append_sheet(wb, ws, 'Totals')

        // set the column widths for the spreadsheet
        const wscols = [{wch:12}, {wch:12}, {wch:12}, {wch:12}, {wch:11}, {wch:13}, {wch:10} ]
        ws['!cols'] = wscols
        // ws['!protect'] = true

        writeFile(wb, `WeeklySummaryReport${reportDate.format('YYYY-MM-DD')}.xlsx`)
    }

    const columns = useMemo(() => [
        {
            Header: `Weekly Summary Bonus Report for week ending ${reportDate.format('MM/DD/YYYY')}`,
            id: 'MainHeader',
            columns: [
                {
                    Header: 'Employee Id',
                    accessor: 'employee_id'
                },
                {
                    Header: 'Total Hours',
                    accessor: 'weekly_hrs',
                },
                {
                    Header: 'Total Bonus',
                    accessor: 'weekly_total_bonus'
                },
                {
                    Header: 'Meat Hours',
                    accessor: 'weekly_meat_hrs'
                },
                {
                    Header: 'Meat Bonus',
                    accessor: 'weekly_meat_bonus'
                },
                {
                    Header: 'Butcher/Vacuum Hours',
                    accessor: 'weekly_butch_vac_hrs'
                },
                {
                    Header: 'Butcher/Vacuum Bonus',
                    accessor: 'weekly_butch_vac_bonus'
                },
                {
                    Header: 'Total Production Bonus',
                    accessor: 'weekly_production_bonus'
                },                
                {
                    Header: 'Global Hours',
                    accessor: 'weekly_global_hrs'
                },
                {
                    Header: 'Global Bonus',
                    accessor: 'weekly_global_bonus'
                }
            ],
            
        }
    ], [reportDate])

    return (
        <div className="report">
            <h3>Weekly Summary Bonus Report</h3>
            <div className="reportdate">
                <WeeklyReportDate reportDate={reportDate} updateReportDate={setReportDate}/>
                <div className="reportdate__button">
                    <button className="button" onClick={getReportDate} disabled={loading}>Generate Report</button>
                </div>
            </div>
            { loading && <h3>Loading...</h3>}
            {errorMsg && <p className="form__error">{errorMsg}</p>}
            <div className="table-layout">
                <div className="button--group">
                    {reportData.length > 0 && <button className="button--link" onClick={downloadPDF}>.pdf</button> }
                    {reportData.length > 0 && <button className="button--link" onClick={downloadXLSX}>.xlsx</button> }
                </div>
                {reportData.length > 0 && <ReportTable columns={columns} data={reportData} tableId={"reportData"}/>}
            </div>
        </div>
    )
}

export default WeeklySummary
