import { useState, useEffect, useRef } from 'react'

const ScaleItem = ({scaleItemNo, scaleItem, setScaleData}) => {
    const [overrideHours, setOverrideHours] = useState(scaleItem.override_hrs)
    const prevOverrideHours = useRef()

    const onOverrideFieldChange = (e) => {
        setOverrideHours(e.target.value)
        prevOverrideHours.current = overrideHours
    }

    useEffect(() => {
        if (overrideHours || prevOverrideHours.current) {
            const newScaleItem = scaleItem
            newScaleItem.override_hrs = overrideHours
            setScaleData(scaleItemNo, newScaleItem)
        }
    }, [overrideHours, scaleItem, scaleItemNo, setScaleData])

    return (
        <tr>
            <td>
                {scaleItem.employee_id}
            </td>
            <td>
                {scaleItem.start_time}
            </td>
            <td>
                {scaleItem.end_time}
            </td>
            <td>
                {scaleItem.total_weight}
            </td>
            <td>
                {scaleItem.ct_hours || '-'}
            </td>
            <td>
                {scaleItem.butch_vac_hours || '-'}
            </td>
            <td>
                {scaleItem.work_hrs}
            </td>
            <td>
                {scaleItem.available_hours}
            </td>
            <td>
                <input 
                    type="number"
                    step="0.01"
                    min="0"
                    max={scaleItem.available_hours}
                    className="text-input__scale"
                    name="overrideWorkHours"
                    value={overrideHours}
                    onChange={e => onOverrideFieldChange(e) } />
            </td>
        </tr>
    )
}

export default ScaleItem